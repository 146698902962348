import { each } from "lodash-es"

import consumer from "@/config/channels/consumer"

export default {
  beforeDestroy() {
    each(this.webSocketChannels, channel => consumer.subscriptions.remove(channel))
  },

  data() {
    return {
      webSocketChannels: []
    }
  },

  methods: {
    webSocketSubscribe(channel, events = {}) {
      this.webSocketChannels.push(consumer.subscriptions.create({ channel }, events))
    }
  }
}
