<template lang="pug"></template>

<script>
  import withWebSocket from "@/mixins/withWebSocket"
  import { showToast } from "@/helpers/toasts"

  export default {
    mixins: [withWebSocket],

    mounted() {
      this.webSocketSubscribe("ManualExportFailedChannel", {
        received: this.handleFailedExportChannelMessage
      })
    },

    methods: {
      handleFailedExportChannelMessage() {
        showToast({
          title: this.$t("inventory_management.manual_export.export_error"),
          text: this.$t("inventory_management.manual_export.changes_were_unapplied")
        })
      }
    }
  }
</script>
